import React from "react";
import Layout from "../../components/layout";
import Bots from "../../components/views/solutions/bots";

const BotsPage = () => {
  return (
    <Layout>
      <Bots />
    </Layout>
  );
};

export default BotsPage;
